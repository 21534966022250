@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif !important;
  background-color: #393E46 !important;
  overflow-x: hidden;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body h6 {
  color: var(--white-grey, #EEE);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

body h5 {
  color: #FFF;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media only screen and (max-width: 600px) {
  .container{
    padding-left: 15px !important;
  }
  body h6 {
    color: var(--white-grey, #EEE);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  body h5 {
    color: #FFF;
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.p-content-box p {
    color: var(--grey, #393E46);
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.p-content-box {
    border-radius: 14px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(14.399999618530273px);
    width: 401px;
    height: 170px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: end;
    padding: 30px 20px 16px 20px;

}

.pcard .header h3 {
    color: var(--dark-sea-green, #9FBB73);
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding-right: 20px;
    padding-bottom: 10px;
}

.pcard .header {
    display: flex;
    justify-content: space-between;
    position: relative;
    bottom: -40px;
    z-index: 1;
}

.philosohy {
    /* height: 100vh; */
    padding-top: 76px;
    padding-bottom: 146px;
}

.m-icon {
    display: none;
}

@media only screen and (max-width: 600px) {
    .pcard .icon {
        display: none;
    }

    .m-icon {
        display: block;
    }

    .p-content-box p {
        color: var(--grey, #393E46);
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .p-content-box {
        width: 330.617px;
        height: 140.162px;
        flex-shrink: 0;
        align-items: center;
        padding: 30px 10px 0 10px;
    }

    .pcard .header h3 {
        color: var(--dark-sea-green, #9FBB73);
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }
   
}
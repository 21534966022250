.services .content-container{
    margin-bottom: 30px;
}
.s-card{
    width: 220px;
    display: flex;
    background: linear-gradient(180deg,#000 30%, #BCBCBC 30%);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10%;
}
.s-card * {
    text-align: center;
}

.icon-inner{
    background: black;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 2px solid #9FBB73;
    
    display: flex;
    justify-content: center;
    align-items: center;
}
.services .icon .icon-inner img{
    width: 55px;

}
.services .icon{
    background: black;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 0px;
}


.s-card h3{
    font-size: 22px;
    font-weight: bold;
}
.s-card p{
    font-size: 12px;
    width: 70%;
    margin: 0 0  20px 0;
}

.enquire{ 
    font-size: 22px !important;
}
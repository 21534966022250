.contact-us p {
    font-size: 18px;
    font-weight: bold;
    color: #ffffff6c;

}
.contact-us h3 {
    color: var(--light-salmon, #EC8F5E);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.content-side {
   border-right: 2px solid #ffffff6c;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 20px;
}
.links{
    justify-content: center;
    gap: 10px;
}
.contact-us{
    display: flex;
    margin-top: 100px;
    margin-bottom: 100px;
}

.form-textarea{
    
    width: 100%;
    box-sizing: border-box;
    height: 200px;
    color: white;
    padding: 14px;
    border: none;
    background: #4B4B4B;
    border-bottom: 1px solid #EEEEEE;
    outline: none;
}
.form-input{
    
    width: 100%;
    box-sizing: border-box;
    height: 49px;
    color: white;
    padding: 14px;
    border: none;
    background: #4B4B4B;
    border-bottom: 1px solid #EEEEEE;
    outline: none;
}
.form-group{
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 50px;
}
.form-group label{
    color: #EEEEEE;
    justify-self: start;
}

.phone-input{
    width: 100%;
    position: relative;
    display: flex;
}
.phone-input span{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 10px;
    color: #EEEEEE;
}



.form-container .contact-form{
    width: 80%;
}
.form-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

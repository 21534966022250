.banner {
    background: #393E46;
    height: 100vh;
}

.banner .header {
    height: 132px;
    display: flex;
    justify-content: space-between;
    width: 100vw;
    /* height: 126px; */
    /* background: blue; */
    align-items: center;
}

.banner .header .logo img {
    width: 112px;
}

.header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu {
    cursor: pointer;
}

.banner h3 span {
    color: #EC8F5E;
    font-family: Poppins;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.banner h3 {
    color: var(--white-grey, #EEE);
    font-family: Poppins;
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.banner h4 {
    color: var(--light-salmon, #EC8F5E);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.banner-image-container {
    position: relative;
}

.banner-image img {
    width: 697px;
    /* height: 558px; */
    /* position: absolute; */
    bottom: 0;
}

.banner_element img {
    width: 160px;
}

.banner_element {
    position: absolute;
    left: 0;
    bottom: 0;
}

@media only screen and (max-width: 600px) {
    .header-inner{
        width: 90vw !important;
    }
    .banner .header .logo img {

        width: 59.832px;
    }
    .menu{
        right: 22px !important;
    }
    .banner h3,
    .banner h3 span {
        font-size: 32px;
    }
    .banner h4  {
        font-size: 16px;
    }
    .banner_element{
        display: none;
    }
    .banner-image img{
        width: 428.313px;
        position: relative;
        left: -45px;
        bottom: -49px;
    }
    .content-box{
        padding-left: 18px;
    }
}


.nav {
    display: flex;
    justify-content: flex-end;
}

.menu {
    display: block;
    position: absolute;
    top:30px;
    right: 60px;
    padding: 20px;
    /* background: black; */
    color: #fff;
    z-index: 9;
}

.navigation {
    position: fixed;
    width: 100%;
    height: 100vh;
    right: 0;
    top: 0;
    z-index: 8;
    transform: scaleY(0);
    background: rgb(0, 0, 0);
    transform-origin: top;
    display: flex;
    justify-content: center;
    align-items: end;
    padding-right: 100px;
    flex-direction: column;
    transition: all 0.2s cubic-bezier(0, 0.32, 0.36, 0.97);
}

.navigator {
    margin: 10px;
    background: transparent;
    color: #fff;
    font-weight: 900;
    letter-spacing: 1px;
    font-size: 34px;
    border:none;
    transform: scaleY(0);
    transform-origin: bottom;
    opacity: 0;
    transition: all 0.5s linear;
}

.navigator:hover{
    color: #5A67D3;
}



.navigation.open {
    transform: scaleY(1);
}

.navigation.open .navigator {
    transform: scaleY(1);
    opacity: 1;
}

.navigation button:hover{
    -webkit-text-stroke: 1px #F3B664;

}
.navigation button{
    -webkit-text-stroke: 1px #fff;
    font-weight: 800;
    -webkit-text-fill-color: transparent;
    /* -webkit-text */
}
.menu-indicator{
    position: absolute;
    right: -260px;
    font-size: 120px;
    transform: rotate(-90deg) !important;
    -webkit-text-stroke: 1px #ffffff4d;
    -webkit-text-fill-color: transparent;
}


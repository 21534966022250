.skills p{
    font-size: 22px;
    font-weight: bold;
    color: #ffffff6c;
}
.skills .content-container .row{
    width: 70%;
}
.skills .content-container{
    display: flex;
    position: relative;
    z-index: 1;
    align-content: end;
    justify-content: end;
}

.skills-img{
    transform: translateY(-40%);
}

.mob-carousel{
    display: none;
}

@media only screen and (max-width: 600px) {
    .mob-carousel{
        display: block;
    }
    .skills-img{
        display: none;
    }
    .skills .content-container .row{
        width: 100%;
        margin-bottom: 50px;
    }
    .skills p{
        font-size: 16px;
    }
    .skills .content-container{

        padding-right: 10px;
    }
}
button.primary{
    color: #EC8F5E;
    font-weight: bold;
    border: 2px solid #EC8F5E;
    cursor: pointer;
}
button.primary:hover{
    color: #fff;
    font-weight: bold;
    border: 2px solid #fff;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
html{
    overflow-x: hidden;
}
}